/**
 * Pallet build screen functionality
 */

/* globals saveGenericTransaction, getLocationMapping, showAlert, scanLocation, isNumeric, padNumber, palletsScanned,
   attachInputEvents, playSuccessSound, attachInputEvents, deductTrailerContent, isCaskOnTrailer, generateScanPalletPopup,
   createBarcodeObject, countTrailerCasks, generateChangeTrailerPopup, generatePopup, getLabels, createKeyAction, createFollowUpTransaction,
   showNotification, showErrorNotification, createFollowUpTransaction, createCompleteAction */

// Build options are used to control the pallet build screen.
var buildOptions = {
  usingRealPallets: false,
  usingTrailers: false,
  alsoStowing: false,
  bayCapacity: (localStorage.getVariable('settings/palletBayCapacity') !== "")?parseInt(localStorage.getVariable('settings/palletBayCapacity')):6,
  stackCapacity: (localStorage.getVariable('settings/palletStackCapacity') !== "")?parseInt(localStorage.getVariable('settings/palletStackCapacity')):7,
  palletSize: 6,
  palletCount: 0
};

/**
 * @description Checks settings to see if real pallet numbers are used, or virtual pallets.
 * @returns {boolean} True if real pallets are being used
 */
function areRealPalletsInUse() {
  "use strict";
  var value = localStorage.getVariable("settings/useRealPallets");
  return (value.toUpperCase() === "TRUE" || value.toUpperCase() === "YES");
}

/**
 * @description Validates the location that is used to stow when also stowing after build
 * @param {string} bay
 * @param {string} rack
 * @param {string} level
 * @param callback Function to run once the details have been validated and saved.
 */
function validateSetBuildLocation(bay, rack, level, callback) {
  "use strict";
  var labelMap = getLocationMapping();
  if (bay === "" || bay.length > labelMap.bay.length) {
    showAlert("Invalid Bay",{focus: "startStowBay"});
    callback(false);
  } else
  if (rack > buildOptions.bayCapacity || rack.length > labelMap.rack.length) {
    showAlert("Invalid Stack", {focus: "startStowRack"});
    callback(false);
  } else
  if (level > buildOptions.stackCapacity || level.length > labelMap.level.length) {
    showAlert("Invalid Level", {focus: "startStowLevel"});
    callback(false);
  } else {
    scanLocation.bay = (isNumeric(bay))?padNumber(labelMap.bay.length,bay):bay;
    scanLocation.rack = (isNumeric(rack))?padNumber(labelMap.rack.length,rack):rack;
    scanLocation.level = (isNumeric(level))?padNumber(labelMap.level.length,level):level;
    callback(true);
  }
}

/**
 * @description Shows the location dialog for when also stowing to set the start location.
 */
function showChangeStartLocationPopup() {
  "use strict";
  //Create the modal
  generatePopup("stowPalletStartLocation","Enter Location","",true);
  $('#stowPalletStartLocation-modalBody').load('../popups/ds.pallet-build-start.popup.html', function() {
    //Create Key action handlers and set values
    attachInputEvents(); //Attaches back button to Inputs on the popup
    var labels = getLabels();
    $('#startStowBayLabel').html(labels.pallet.bay);
    $('#startStowBay').val(scanLocation.bay).focus();
    $('#startStowRackLabel').html(labels.pallet.rack);
    $('#startStowRack').val(scanLocation.rack);
    $('#startStowLevelLabel').html(labels.pallet.level);
    $('#startStowLevel').val(scanLocation.level);
    createKeyAction("#startStowBay","#startStowRack");
    createKeyAction("#startStowRack","#startStowLevel");
    createCompleteAction("#startStowLevel","#confirmStartButton");
    //Event on confirm press
    $('#confirmStartButton').on('click', function() {
      validateSetBuildLocation($('#startStowBay').val(),$('#startStowRack').val(),$('#startStowLevel').val(), function(valid) {
        if(valid) {
          $('#bayInfo').html(scanLocation.bay).show();
          $('#stackInfo').html(scanLocation.rack).show();
          $('#stowPalletStartLocation-modal').modal("hide");
        }
      });
    });
  });
}

/**
 * @desc Shows the UI for scanning and stowing Pallets
 * @param usingTrailers {Boolean} Are casks being removed from a trailer
 * @param alsoStowing {Boolean} Are pallets to be stowed at the same time
 */
function showPalletBuildScreen(usingTrailers, alsoStowing) {
  'use strict';
  buildOptions.usingRealPallets = areRealPalletsInUse();
  buildOptions.usingTrailers = usingTrailers;
  buildOptions.alsoStowing = alsoStowing;
  buildOptions.palletSize = parseInt(localStorage.getVariable("settings/palletSize"));
  //Load parameters
  buildOptions.bayCapacity = (localStorage.getVariable('settings/palletBayCapacity') !== "")?parseInt(localStorage.getVariable('settings/palletBayCapacity')):6;
  buildOptions.stackCapacity = (localStorage.getVariable('settings/palletStackCapacity') !== "")?parseInt(localStorage.getVariable('settings/palletStackCapacity')):7;
  //Load the template.
  $('#programContainer').load('../templates/ds.pallet-build.template.html', function () {
    attachInputEvents();
    var labels = getLabels();

    // Show Manual SWA if appropriate. This returns true if so, so if true we can add the event handler.
    if (checkAndShowManualBarcodeEntry()) {
      // Add handler for manual entry
      $("#manualScanButton").on('click', function() {
        loadManualCaskPopup(previous_scan, function(valid, manualBarcodeObject) {
          if (valid) {
            // If the manual details were valid, the barcode object from it is passed into process.
            processBuildPalletScan(manualBarcodeObject);
            // Normally hiding would be done on callback but no errors in the above would help determine this.
            $("#manualCaskPopup-modal").modal("hide");
          }
        });
      });
    }

//    Label fields
    $('#inputLabel').html("Scan " + labels.caskLabel + ":");
    $('#scannedCasksLabel').html(labels.caskLabel + 's:');
    $('#scannedCasksCount').html("0 of " + buildOptions.palletSize);
//    display trailer info
    if (buildOptions.usingTrailers) {
      $('#trailerHeader').show();
      var trailerInfo = countTrailerCasks(localStorage.getItem("scanTrailer"));
      localStorage.setVariable("scanTrailerContent", trailerInfo.content, true);
      $('#trailerInfo').html(localStorage.getItem("scanTrailer") + ' (0/' + trailerInfo.content + ')').show();
      $('#changeTrailerColumn').css("display", "block");
      $('#changeTrailerButton').show().on('click', function () {
        generateChangeTrailerPopup(function () {
          //    Callback once the trailer value has changed.

          //todo this is bad - need a better function to get trailer Info.
          var trailerInfo = countTrailerCasks(localStorage.getItem("scanTrailer"));
          localStorage.setVariable("scanTrailerContent", trailerInfo.content, true);
          $('#trailerInfo').html(localStorage.getItem("scanTrailer") + ' (0/' + trailerInfo.content + ')').show();
        });
      });
    }
    // Show Location Information
    if (buildOptions.alsoStowing) {
      $('#bayHeader').html(labels.pallet.bay).show();
      $('#stackHeader').html(labels.pallet.rack).show();
      $('#bayInfo').html(scanLocation.bay).show();
      $('#stackInfo').html(scanLocation.rack).show();
      $('#changeLocationColumn').css("display", "block");
      $('#changeLocationButton').show().on('click', function () {
        showChangeStartLocationPopup();
      });
    }

    // Show Pallet Number when using real pallets.
    if (buildOptions.usingRealPallets) {
      // The field will be used to show the current pallet rather than a count of pallets built.
      $('#palletHeader').html('Pallet');
    }

    // Add event for pressing complete pallet button
    $("#completePalletButton").attr("disabled","disabled").on("click", function () {
      completePallet();
    });
    // If both then adjust the cask scroll
    if (buildOptions.usingTrailers && buildOptions.alsoStowing) {
      $('#palletCasks').css("height", "135px");
      $('#completePalletButton').css("margin-top", "0");
    }

    $("#scanInput").focus();
    // If allowing real pallets, then force the popup for scanning pallet to show first.
    if (buildOptions.usingRealPallets) {
      generateScanPalletPopup(updateRealPalletForBuilding);
    }

    createEnterAction('#scanInput', true, function(scannedCask) {

      var barcodeObject = createBarcodeObject(scannedCask, "scanInput");
      if (barcodeObject.passed) {
        processBuildPalletScan(barcodeObject);
      }
    });
  });
  attachInputEvents();
}

/**
 * @description Processes a build pallet scan, saves the transaction, and completes the pallet when full.
 * @param {barcodeObject} barcodeObject
 */
function processBuildPalletScan(barcodeObject) {
  "use strict";
  // Get the pallet from LocalStorage or create a new one. New ones should only be created here when virtual pallets are in use
  var currentPallet = (localStorage.getItem("currentPallet")) ? localStorage.getObject("currentPallet") : createNewPallet(0);
  // Prevent the scan and error if real pallets are in use, and the number is 0.
  if (buildOptions.usingRealPallets) {
    if (!currentPallet.virtual && currentPallet.number === 0) {
      showAlert("Error. No pallet has been scanned. Scan now?", {canCancel: true, cancelText: 'Cancel', callback: function() {
          // If the user selects OK they will then be shown the scan pallet dialog again.
          generateScanPalletPopup(updateRealPalletForBuilding);
        }});
      return;
    }
  }

  // add to pallet
  currentPallet.content++;
  $("#completePalletButton").removeAttr("disabled");

  if (localStorage.getItem("scanTrailer")) {
    // update trailer counter
    if (isCaskOnTrailer(localStorage.getItem("scanTrailer"), barcodeObject)) {
      //update trailer counter
      var trailerInfo = deductTrailerContent(localStorage.getItem("scanTrailer"));
      $("#trailerInfo").html(localStorage.getItem("scanTrailer") + ' (' + trailerInfo.content + '/' + trailerInfo.oldContent + ')');
    } else {
      showAlert("This cask was not on the trailer", {focus: "scanInput"});
    }
  }
  //save the transaction
  saveGenericTransaction(barcodeObject, null, "", function (success) {
    if (success) {
      playSuccessSound(true);

      // check pallet ain't full
      if (currentPallet.content === buildOptions.palletSize) {
        // - if full - clear box and pallet, ready for new pallet.
        completePallet();
      } else {
        $("#scannedCasksCount").html(currentPallet.content + ' of ' + buildOptions.palletSize);
        // If the barcode object is for SWA labels we might need to truncate the make code.
        // This is difficult here as we normally just display the barcode displayCode field.
        // We need to replace the make value as we dont know where it might live in the mapping.
        if (barcodeObject.vesselBarcodeType === VesselBarcodeType.scotch) {
          // Get the length of the swa make code to use in truncation
          // If the length needs to be truncated then do so, and then replace in the value to be shown on screen.
          var makeLength = barcodeObject.swa.make.length;
          if (makeLength > 9) {
            var shorterMake = limitString(barcodeObject.swa.make, 9);
            barcodeObject.displayCode = barcodeObject.displayCode.replace(barcodeObject.swa.make, shorterMake);
          }
        }
        $("#palletCasks").prepend('<tr><td colspan="2">' + barcodeObject.displayCode + '</td></tr>');
        localStorage.setObject("currentPallet", currentPallet);
      }
    }
  });
}

/**
 * @description Updates the current pallet for build operations and displays on the pallet build screen.
 * @param {Number} palletNo
 */
function updateRealPalletForBuilding(palletNo) {
  "use strict";
  createNewPallet(palletNo);
  palletsScanned.push(palletNo);
  $("#palletInfo").html(palletNo);
}

/**
 * @description Creates a new pallet object and saves to LocalStorage.
 * @param {Number} palletNo
 * @returns {{number: *, content: number}}
 */
function createNewPallet(palletNo) {
  "use strict";
  // If we are using real pallets then we accept an input number, otherwise we generate one.
  var palletId = (areRealPalletsInUse())? palletNo : new Date().getTime() + localStorage.getItem("scannerid");
  var newPallet = {
    number: palletId,
    content: 0,
    virtual: !areRealPalletsInUse()
  };
  localStorage.setObject("currentPallet", newPallet);
  return newPallet;
}

/**
 * @desc clears the pallet counter and list of casks on the building screen
 */
function completePallet() {
  "use strict";
  $("#scannedCasksCount").html('0 of ' + buildOptions.palletSize);
  buildOptions.palletCount ++;
  if (buildOptions.usingRealPallets) {
    $("#palletInfo").html(''); // blank until the next one has been scanned.
  } else {
    $("#palletInfo").html(buildOptions.palletCount);
  }
  $("#palletCasks").html('');
  $("#completePalletButton").attr("disabled","disabled");
  localStorage.removeItem("currentPallet");
  if(buildOptions.alsoStowing) {
    showPalletStowConfirmation();
  } else {
    $("#scanInput").focus();
    // If allowing real pallets, then force the popup for scanning pallet to show first.
    if (buildOptions.usingRealPallets) {
      generateScanPalletPopup(updateRealPalletForBuilding);
    }
  }
}

/**
 * @description Show the stow confirmation dialog when also stowing.
 */
function showPalletStowConfirmation() {
  "use strict";
  //generate the modal
  generatePopup("confirmPalletStowPopup","Confirm Stow","",false);
  $('#confirmPalletStowPopup-modalBody').load('../popups/ds.pallet-build-stow.popup.html', function() {
    //Once the content is loaded, we can add button handlers for the "stow pallet" and "leave on floor"
    attachInputEvents(); //Attaches back button to Inputs on the popup
    var labels = getLabels();
    $('#confirmStowBayLabel').html(labels.pallet.bay);
    $('#confirmStowBay').val(scanLocation.bay).focus();
    $('#confirmStowRackLabel').html(labels.pallet.rack);
    $('#confirmStowRack').val(scanLocation.rack);
    $('#confirmStowLevelLabel').html(labels.pallet.level);
    $('#confirmStowLevel').val(scanLocation.level);
    createKeyAction('#confirmStowBay','#confirmStowRack');
    createKeyAction('#confirmStowRack','#confirmStowLevel');
    $('#ignoreStowButton').on('click', function() {
      $("#confirmPalletStowPopup-modal").modal('hide');
      // If allowing real pallets, then force the popup for scanning pallet to show first.
      if (buildOptions.usingRealPallets) {
        generateScanPalletPopup(updateRealPalletForBuilding);
      }
    });
    $('#confirmStowButton').on('click', function() {
      //stow the pallet using the last barcode object
      validateSetBuildLocation($('#confirmStowBay').val(),$('#confirmStowRack').val(),$('#confirmStowLevel').val(), function(valid) {
        if(valid) {
          //Hardcoded to STOW. If this is not suitable in future we would need transaction code mappings
          createFollowUpTransaction("STOW", function(success) {
            if (success) {
              showNotification("Pallet Stowed");
            } else {
              showErrorNotification("Unable to Stow Pallet");
            }
          });
          incrementStowLocation();
          //then close the modal
          $("#confirmPalletStowPopup-modal").modal('hide');
          // If allowing real pallets, then force the popup for scanning pallet to show first.
          if (buildOptions.usingRealPallets) {
            generateScanPalletPopup(updateRealPalletForBuilding);
          }
        }
      });
    });
  });
}

function incrementStowLocation() {
  "use strict";
  if (parseInt(scanLocation.level) >= buildOptions.stackCapacity) {
    scanLocation.rack = padNumber(scanLocation.rack.length,(parseInt(scanLocation.rack)+1).toString());
    scanLocation.level = padNumber(scanLocation.level.length,"1");
    if (parseInt(scanLocation.rack) > buildOptions.bayCapacity) {
      //Our stack number now exceeds capacity of the bay.
      //Bay increment too
      scanLocation.rack = padNumber(scanLocation.rack.length,"1");
      scanLocation.bay  = padNumber(scanLocation.bay.length,(parseInt(scanLocation.bay)+1).toString());
    }
  } else {
    scanLocation.level = padNumber(scanLocation.level.length,(parseInt(scanLocation.level)+1).toString());
  }
  $('#bayInfo').html(scanLocation.bay);
  $('#stackInfo').html(scanLocation.rack);
}
